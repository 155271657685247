<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">租户列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="isadd = true">添加租户</el-button>
		</div>
		<el-table :data="list" style="width: 100%" stripe border>
			<el-table-column type="index" align="center" width="50" />
			<el-table-column align="center" prop="tenantName" label="租户名称" />
			<el-table-column align="center" prop="streetAddress" label="地址" />
			<el-table-column label='操作' align="center">
				<template #default="scope">
					<el-button size="small" type="primary" @click="edits(scope.row)">修改
					</el-button>
					<el-button size="small" type="danger" @click="deletes(scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="elpagination">
			<el-pagination background layout="prev, pager, next" @current-change="pagechange" :page-count="pages" />
		</div>
	</div>



	<el-dialog v-model="isadd" title="添加租户" width="20%">
		<div class="row">
			<div class="row-title">租户租户名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.tenantName" placeholder="请输入要添加的租户租户名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">租户地址<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.streetAddress" placeholder="请输入租户地址" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">初始管理帐号<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.identifierName" placeholder="请输入租户地址" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">初始管理员密码<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="addjson.identifierPassword" placeholder="请输入租户地址" clearable type="text"></el-input>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isadd = false">取消</el-button>
				<el-button type="primary" @click="submitadd" :loading="loading">添加</el-button>
			</span>
		</template>
	</el-dialog>

	<el-dialog v-model="isedit" title="修改租户" width="20%">
		<div class="row">
			<div class="row-title">租户租户名称<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.tenantName" placeholder="请输入要添加的租户租户名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">租户地址<el-tag type="danger" size="small">必填</el-tag>
			</div>
			<el-input v-model="editjson.streetAddress" placeholder="请输入租户地址" clearable type="text"></el-input>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isedit = false">取消</el-button>
				<el-button type="primary" @click="submitedit" :loading="loading">修改</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		tenantslist,
		tenantsadd,
		tenantsedit,
		tenantsdelete
	} from "../../util/api"
	export default {
		data() {
			return {
				pageNum: 1,
				pageSize: 15,
				pages: 1,
				list: null,
				isadd: false,
				isedit: false,
				addjson: {
					tenantName: null,
					streetAddress: null,
					identifierPassword: null,
					identifierName: null
				},
				editjson: {
					id: null,
					tenantName: null,
					streetAddress: null
				},
				loading: false
			}
		},
		created() {
			this.getlist()
		},
		methods: {
			submitedit() {
				if (!this.editjson.tenantName) {
					this.$message({
						type: 'warning',
						message: '请输入租户名称',
					})
				} else if (!this.editjson.streetAddress) {
					this.$message({
						type: 'warning',
						message: '请输入租户地址',
					})
				} else {
					this.loading = true
					tenantsedit(this.editjson).then((res) => {
						this.loading = false
						this.isedit = false
						this.$message({
							type: 'success',
							message: '修改成功',
						})
						this.getlist()
					}).catch((error) => {
						this.loading = false
					})
				}
			},
			edits(row) {
				this.editjson = {
					id: row.id,
					tenantName: row.tenantName,
					streetAddress: row.streetAddress
				}
				this.isedit = true
			},
			deletes(row) {
				this.$confirm('是否删除租户“' + row.tenantName + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					tenantsdelete(row.id).then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getlist()
					})
				})
			},
			submitadd() {
				if (!this.addjson.tenantName) {
					this.$message({
						type: 'warning',
						message: '请输入租户名称',
					})
				} else if (!this.addjson.streetAddress) {
					this.$message({
						type: 'warning',
						message: '请输入租户地址',
					})
				} else if (!this.addjson.identifierName) {
					this.$message({
						type: 'warning',
						message: '请输入初始管理帐号',
					})
				} else if (!this.addjson.identifierPassword) {
					this.$message({
						type: 'warning',
						message: '请输入初始管理密码',
					})
				} else {
					this.loading = true
					tenantsadd(this.addjson).then((res) => {
						this.loading = false
						this.isadd = false
						this.$message({
							type: 'success',
							message: '添加成功',
						})
						this.addjson = {
							tenantName: null,
							streetAddress: null
						}
						this.getlist()
					}).catch((error) => {
						this.loading = false
					})

				}
			},
			getlist() { //获取列表
				tenantslist({
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}).then((res) => {
					this.list = res.data.list
					this.pages = res.data.pages
				})
			},
			pagechange(index) {
				this.pageNum = index
				this.getlist()
			}
		}
	}
</script>

<style scoped>

</style>
